<template>
  <div>
    <van-popup
      v-model:show="show"
      position="right"
      lock-scroll
      close-on-popstate
      :overlay-style="{ background: 'rgba(0, 0, 0, .7) url(https://artimg2.artart.cn/image/20220302/80677cce97eac55142063cc7bc9c117c.png) no-repeat 0.6rem center' }"
      :style="{ width: '85%', height: '100%' }"
    >
      <div class="all-auction flex">
        <div class="all-t flex justify-c">
          <div class="fw500">本场拍品</div>
        </div>
        <div class="all-num flex align-items-c textAlignC ft12 colorGray">
          <div class="item flex-one">
            <div class="num">{{ statistical.total_num || 0 }}</div>
            <div class="txt">拍品数量</div>
          </div>
          <div class="item flex-one">
            <div class="num">{{ statistical.surplus_num || 0 }}</div>
            <div class="txt">剩余拍品</div>
          </div>
          <div class="item flex-one">
            <div class="num">{{ statistical.deal_rate || 0 }}%</div>
            <div class="txt">成交率</div>
          </div>
          <div class="item flex-one">
            <div class="num">{{ statistical.deal_price_format || 0 }}</div>
            <div class="txt">成交总额</div>
          </div>
        </div>
        <div class="auction-list-tab flex textAlignC ft14 fw500">
          <div
            class="tab flex-one"
            :class="{ 'curr': changeStatue == 1 }"
            @click="loadAuctionStatus(1)"
          >进行中
            <div class="bg"></div>
          </div>
          <div
            class="tab flex-one"
            :class="{ 'curr': changeStatue == 2 }"
            @click="loadAuctionStatus(2)"
          >已结束
            <div class="bg"></div>
          </div>
        </div>
        <div class="auction-list">
          <div class="list-show">
            <van-list
              class="list"
              v-model="loading"
              :finished="finished"
              :immediate-check="false"
              finished-text="我也是有底线的"
              @load="loadList"
            >
              <template
                v-for="(item, index) in list"
                :key="`list${index}`"
              >
                <div
                  class="item flex"
                  @click="showDetailAction(item.product_id)"
                >
                  <template v-if="item.product_id">
                    <img
                      :src="item.image + '!120a'"
                      v-if="item.image"
                    >
                    <div class="item-info flex-one" style="overflow: hidden;">
                      <div class="lot ft12 colorGray">LOT {{item.lot}}</div>
                      <div class="name ft12 fw500">{{item.store_name}}</div>
                      <div class="price ft12 colorGray">
                        <template v-if="item.time_status == 1 || (item.time_status == 2 && !item.now_price)">起拍价</template>
                        <template v-else-if="item.time_status == 2 && item.now_price">当前价</template>
                        <template v-else-if="item.time_status == 3 && item.now_price">落槌价</template>
                        {{item.now_price || item.start_price ? (merchantInfo.fee_type == 'CNY' ? 'RMB' : merchantInfo.fee_type) : ''}} <span v-if="item.time_status == 1 || (item.time_status == 2 && !item.now_price)">{{item.start_price ?
                    formatPriceNumber(item.start_price, 2) : '无底价'}}</span>
                        <span v-else-if="item.now_price">{{ formatPriceNumber(item.now_price, 2) }}</span>
                      </div>
                      <div
                        class="status"
                        v-if="item.time_status == 3"
                      >结拍</div>
                      <div
                        class="status now"
                        v-else-if="item.time_status == 2"
                      >正在拍卖</div>
                      <div
                        class="status"
                        v-else-if="item.time_status == 1"
                      >待开始</div>

                      <template v-if="item.time_status == 1">
                        <div
                          class="flex align-items-c daili ft12 fw500"
                          v-if="item.proxyBidInfo"
                          @click.stop="resetProxyInfo(index)"
                        >
                          <div>取消委托</div>
                        </div>
                        <div
                          v-else
                          class="flex align-items-c daili ft12 fw500"
                          @click.stop="proxyBidAction(index)"
                        >
                          <div>委托出价</div>
                        </div>
                      </template>
                    </div>
                  </template>
                </div>
              </template>
            </van-list>
          </div>
          <!-- <scroll-view
            class="list"
            scroll-y="{{true}}"
            bindscrolltolower="loadMoreAction"
            lower-threshold="100"
          >

          </scroll-view> -->
        </div>
      </div>
    </van-popup>
    <AuctionProxy
      ref="proxy"
      @setOK="setProxyOk"
    />
  </div>
</template>

<script>
import { Popup } from "vant";
import { ref, computed } from "vue";
import { Toast, Dialog } from "vant";
import { formatPriceNumber } from "@/utils/util";
import { auctionList, albumAuctionStatistical, cancelProxy } from "@/api/auction";
import AuctionProxy from "@/components/AuctionProxy";
import { useStore } from 'vuex';

export default {
  components: {
    [Popup.name]: Popup,
    AuctionProxy,
  },
  props: {
    merchant: {
      type: Object,
      default: () => ({}),
    },
    merchantid: {
      type: [Number, String],
      default: '',
    },
  },
  setup(props, content) {
    const store = useStore();
    const show = ref(false);

    const loading = ref(false);
    const pageLoading = ref(false);
    const finished = ref(false);
    const albumStatisticalData = ref({}); // 拍品列表 数据统计
    const changeStatue = ref(1); // 拍品列表筛选状态
    const list = ref([]); // 拍品列表

    const queryParams = ref({
      page: 1,
      limit: 10,
    });
    const total = ref(0);
    const statistical = ref({});

    const loadAlbumData = (id) => {
      albumAuctionStatistical({
        album_id: id,
        merchant_id: props.merchantid || '',
      })
        .then((res) => {
          statistical.value = res.data;
        })
        .catch((message) => Toast(message));
    };

    const loadList = () => {
      if (finished.value) return false;
      if (pageLoading.value) return false;
      pageLoading.value = true;
      loading.value = true;
      if (changeStatue.value === 1) {
        queryParams.value.status = 3;
      } else if (changeStatue.value === 2) {
        queryParams.value.status = 4;
      }
      auctionList(queryParams.value)
        .then((res) => {
          total.value = res.data.count;
          if (queryParams.value.page === 1) {
            list.value = res.data.list;
          } else {
            list.value = list.value.concat(res.data.list);
          }
          if (list.value.length >= res.data.count) {
            finished.value = true;
          }
          queryParams.value.page += 1;
          pageLoading.value = false;
          loading.value = false;
        })
        .catch((message) => Toast(message));
    };

    const loadAuctionStatus = (val) => {
      if (val !== changeStatue.value) {
        changeStatue.value = val;
        list.value = [];
        queryParams.value.page = 1;
        finished.value = false;
        loading.value = false;
        pageLoading.value = false;
        loadList();
      }
    };
    const proxy = ref(null);
    let setProxyIndex = "";
    const proxyBidAction = (index) => {
      // 设置代理出价
      setProxyIndex = index;
      const item = list.value[index];
      proxy.value.openSetProxy(item.product_id);
    };

    const setProxyOk = (price) => {
      // 代理出价设置完成回调
      if (setProxyIndex) {
        list.value[setProxyIndex].proxyBidInfo = {
          proxy_price: price,
        };
      }
    };

    let saveLock = false;
    const resetProxyInfo = (index) => {
      // 取消代理出价
      const item = list.value[index];
      if (saveLock) return false;
      saveLock = true;
      Toast.loading({
        forbidClick: true,
        duration: 0,
      });
      cancelProxy({
        auction_id: item.product_id,
      })
        .then(() => {
          Toast.clear();
          saveLock = false;
          Toast('取消成功');
          list.value[index].proxyBidInfo = null;
        })
        .catch((message) => {
          Toast.clear();
          Toast(message);
          saveLock = false;
        });
    };

    const showDetailAction = (id) => {
      if (id) {
        content.emit('showDetail', id);
      }
    }

    const merchantInfo = computed(() => {
      return store.getters.merchantInfo ? store.getters.merchantInfo : {};
    });

    return {
      show,
      formatPriceNumber,

      albumStatisticalData,
      changeStatue,
      list,
      merchantInfo,

      queryParams,
      loading,
      finished,
      statistical,
      proxy,

      loadList,
      loadAuctionStatus,
      loadAlbumData,
      proxyBidAction,
      setProxyOk,
      resetProxyInfo,
      showDetailAction
    };
  },
};
</script>

<style lang="scss" scoped>
.all-auction {
  flex-direction: column;
  height: 100%;
}

.all-t {
  padding: 32px;
}

.all-t div {
  font-size: 32px;
}

.all-t image {
  width: 48px;
  height: 48px;
}

.all-num {
  padding: 14px 32px 32px 32px;
}

.all-num .item .num {
  font-size: 32px;
  font-weight: 500;
  color: #000;
}

.auction-list {
  flex: 1;
  overflow: hidden;
}
.auction-list .list-show {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.auction-list .list {
  width: 100%;
  height: 100%;
}

.auction-list .list .item {
  margin: 0 32px;
  padding: 32px 0;
  border-top: 2px solid #eeeeee;
  height: 226px;
  box-sizing: border-box;
}

.auction-list .list .item:first-child {
  border-top: none;
}

.auction-list .list .item img {
  width: 160px;
  height: 160px;
  border-radius: 8px;
}

.auction-list .list .item .item-info {
  padding-left: 20px;
  position: relative;
}

.auction-list .list .item .name {
  margin-top: 8px;
  height: 68px;
  line-height: 34px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.auction-list .list .item .price {
  margin-top: 8px;
}

.auction-list .list .item .price span {
  font-size: 28px;
  font-weight: 500;
  color: #000;
}

.auction-list .list .item .item-info .status {
  position: absolute;
  right: 0;
  top: 0;
  padding: 2px 8px;
  font-size: 20px;
  font-weight: 500;
  border-radius: 6px;
  border: 2px solid #999999;
  color: #999999;
}

.auction-list .list .item .item-info .status.now {
  border: 2px solid #ed424f;
  color: #ed424f;
}

.auction-list-tab {
  height: 80px;
  border-top: 2px solid #eeeeee;
  line-height: 80px;
  margin: 0 32px;
}

.auction-list-tab div {
  position: relative;
}

.auction-list-tab .curr {
  color: #b8202c;
}

.auction-list-tab .curr .bg {
  display: block;
}

.auction-list-tab .bg {
  display: none;
  width: 40px;
  height: 6px;
  position: absolute;
  left: 50%;
  margin-left: -20px;
  bottom: 0;
  border-radius: 3px;
  background: #b8202c;
}

.daili {
  position: absolute;
  bottom: 0;
  right: 0;
  color: #999;
  bottom: 10px;
}
</style>
