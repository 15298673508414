<template>
  <div>
    <van-dialog
      v-model:show="showProxy"
      :show-confirm-button="false"
    >
      <div class="title flex align-items-c">
        <div class="flex-one fw500">委托出价设置</div>
        <div
          class="anonymous flex align-items-c"
          @click="changeAnonymous"
        >
          <img
            v-if="is_anonymous"
            class="img"
            :style="`background: ${colorData.specialColor}`"
            src="https://artimg2.artart.cn/image/20220310/52b245dfeaebec4450c373731e7da2ee.png"
            alt=""
          >
          <img
            v-else
            class="img"
            :style="`background: ${colorData.specialColor}`"
            src="https://artimg2.artart.cn/image/20220310/47a252d25147755b82bd3e9de98694a7.png"
            alt=""
          >
          <div
            class="ft14 fw500"
            :style="{ color: colorData.specialColor }"
          >匿名</div>
        </div>
        <div
          class="close"
          @click="hideProxyAction"
        >
          <img
            src="https://artimg2.artart.cn/image/20220129/37face345e08861294c4efb7895d443e.png"
            alt=""
          >
        </div>
      </div>
      <div class="go-top flex align-items-c">
        <div class="inp">
          <input
            v-model="proxyMoney"
            type="number"
            placeholder="输入委托出价金额"
            autofocus
          >
        </div>
      </div>
      <div
        class="has flex align-items-c"
        v-if="hasProxy"
        @click="resetProxyInfo"
      >
        <div class="flex1"><span class="color-gray">已委托出价</span> {{formatPriceNumber(hasProxy.proxy_price, 2)}}{{merchantInfo.fee_type_text}}</div>
        <div>取消委托</div>
      </div>
      <div class="common-dialog-btns flex">
        <div
          class="common-dialog-btn common-dialog-r-btn"
          :style="rightBtnStyle"
          @click="setProxyAction"
        >确认设置</div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { ref, computed, toRefs } from "vue";
import { useStore } from "vuex";
import { Toast, Dialog } from "vant";
import { formatPriceNumber } from "@/utils/util";
import { getProxyInfo, addProxy, cancelProxy } from "@/api/auction";

export default {
  props: {
    compel_anonymous: Number,
    merchant: Object,
  },
  setup(props, content) {
    const store = useStore();
    const showProxy = ref(false);
    const proxyMoney = ref("");
    const id = ref("");

    const { compel_anonymous } = toRefs(props);

    const merchantInfo = computed(() => {
      if(props.merchant) {
        return props.merchant;
      }
      return store.getters.merchantInfo ? store.getters.merchantInfo : {};
    });

    // 右按钮样式
    const rightBtnStyle = computed(() => {
      return store.getters.right_button_style
        ? store.getters.right_button_style
        : {};
    });
    // 左按钮样式
    const leftBtnStyle = computed(() => {
      return store.getters.left_button_style
        ? store.getters.left_button_style
        : {};
    });

    // 获取color样式集合
    const colorData = computed(() => {
      return store.getters.color_data ? store.getters.color_data : {};
    });
    const hideProxyAction = () => {
      // 取消代理出价设置
      showProxy.value = false;
      proxyMoney.value = "";
    };

    let saveLock = false;
    const setProxyAction = () => {
      // 确认设置代理出价
      if (!proxyMoney.value || isNaN(proxyMoney.value)) {
        Toast("请设置正确的委托金额");
        return false;
      }
      Dialog.confirm({
        title: `确定设置${proxyMoney.value}${merchantInfo.value.fee_type_text}委托吗？`,
      }).then(() => {
        if (saveLock) return false;
        saveLock = true;
        Toast.loading({
          forbidClick: true,
          duration: 0,
        });
        addProxy({
          auction_id: id.value,
          proxy_price: proxyMoney.value,
          is_anonymous: is_anonymous.value,
        })
          .then(() => {
            hideProxyAction();
            Toast.clear();
            Toast("设置成功");
            saveLock = false;
            content.emit("setOK", proxyMoney.value);
          })
          .catch((message) => {
            Toast.clear();
            Toast(message);
            saveLock = false;
          });
      });
    };

    const hasProxy = ref(null);
    const openSetProxy = (auction_id) => {
      // 打开代理出价设置
      id.value = auction_id;
      getProxyInfo({
        auction_id: auction_id,
      }).then((res) => {
        if (res.data.is_set && res.data.info) {
          hasProxy.value = res.data.info;
          // resetProxyInfo(res.data.info);
        }
        showProxy.value = true;
      });
    };

    const resetProxyInfo = () => {
      Dialog.confirm({
        title: `确认取消${formatPriceNumber(hasProxy.value.proxy_price)}${
          merchantInfo.value.fee_type_text
        }委托出价吗？`,
      }).then(() => {
        if (saveLock) return false;
        saveLock = true;
        Toast.loading({
          forbidClick: true,
          duration: 0,
        });
        cancelProxy({
          auction_id: id.value,
        })
          .then(() => {
            Toast.clear();
            hasProxy.value = null;
            saveLock = false;
          })
          .catch((message) => {
            Toast.clear();
            Toast(message);
            saveLock = false;
          });
      });
    };

    const is_anonymous = ref(1);

    const changeAnonymous = () => {
      if (compel_anonymous.value === 1) {
        is_anonymous.value = 1;
      } else {
        is_anonymous.value = Number(!is_anonymous.value);
      }
    };

    return {
      id,
      showProxy,
      proxyMoney,
      rightBtnStyle,
      leftBtnStyle,
      colorData,
      merchantInfo,
      is_anonymous,
      hasProxy,

      hideProxyAction,
      setProxyAction,
      formatPriceNumber,

      openSetProxy,
      changeAnonymous,
      resetProxyInfo,
    };
  },
};
</script>

<style lang="scss" scoped>
.go-top {
  padding-top: 20px;
}
.title {
  position: relative;
  font-size: 32px;
  padding: 32px 40px 0 40px;

  .anonymous {
    margin-right: 90px;
    .img {
      width: 30px;
      height: 30px;
      margin-right: 4px;
      display: block;
    }
  }
}
.inp {
  margin: 20px 40px 0 40px;
  padding: 0 20px;
  border: 2px solid #ddd;
  border-radius: 8px;
  height: 80px;
  width: 100%;
  box-sizing: border-box;
  input {
    width: 100%;
    border: none;
    padding: 0;
    line-height: 76px;
  }
}
.has{
  color: #08f;
  font-size: 30px;
  margin: 32px 40px 0 40px;
}

.has .flex1{
  flex: 1;
  color: #000;
}
</style>
