<template>
  <div>
    <van-popup
      v-model:show="show"
      position="right"
      lock-scroll
      close-on-popstate
      :overlay-style="{ background: 'rgba(0, 0, 0, .7) url(https://artimg2.artart.cn/image/20220302/80677cce97eac55142063cc7bc9c117c.png) no-repeat 0.6rem center' }"
      :style="{ width: '85%', height: '100%' }"
    >
      <div class="auction-detail flex">
        <div class="all-t flex justify-c">
          <div class="fw500">拍品详情</div>
        </div>
        <template v-if="detailData">
          <div class="detail-name fw500">LOT{{detailData.lot}} {{detailData.product ? detailData.product.store_name : ''}}</div>
          <div class="auction-list">
            <div class="list-show">
              <van-list
                class="list"
                v-model="isLoading"
                :finished="true"
                :immediate-check="false"
              >

                <div class="params">
                  <div
                    class="flex align-items-c"
                    v-if="detailData.reference_price"
                  >
                    <div class="l">估价</div>
                    <div class="r">{{ detailData.reference_price }}</div>
                  </div>
                  <div
                    class="flex"
                    v-if="detailData.album"
                  >
                    <div class="l">拍卖专场</div>
                    <div class="r">共{{detailData.album.auction_num}}件 {{ detailData.album.album_name }}</div>
                  </div>
                  <div
                    class="flex align-items-c"
                    v-if="detailData.album"
                  >
                    <div class="l">本场保证金</div>
                    <div class="r">
                      <template v-if="detailData.album.bond_type == 1">{{ detailData.album.bond_price ? formatPriceNumber(detailData.album.bond_price, 2) + merchantInfo.fee_type_text : '无专场保证金' }}</template>
                      <template v-if="detailData.album.bond_type == 2">1:{{detailData.album.bond_bid_ratio}}(充值1{{ merchantInfo.fee_type_text }}可出价{{detailData.album.bond_bid_ratio}}{{ merchantInfo.fee_type_text }})</template>
                    </div>
                  </div>
                  <div
                    class="flex align-items-c"
                    v-if="detailData.venue"
                  >
                    <div class="l">预展时间</div>
                    <div class="r">{{ parseTime(detailData.venue.preview_start_time, '{y}年{m}月{d}日') }} 至 {{ parseTime(detailData.venue.preview_end_time, '{d}日') }}</div>
                  </div>
                  <div
                    class="flex align-items-c"
                    v-if="detailData.album"
                  >
                    <div class="l">开拍时间</div>
                    <div class="r">{{ parseTime(detailData.album.album_start_time, '{y}年{m}月{d}日 {h}:{i}') }}</div>
                  </div>
                  <div
                    class="flex align-items-c"
                    v-if="detailData.venue && detailData.venue.address_name"
                  >
                    <div class="l">拍卖地点</div>
                    <div class="r">{{ detailData.venue.address_name }}</div>
                  </div>
                  <div
                    class="flex"
                    v-if="detailData.product"
                  >
                    <div class="l">介绍</div>
                    <div class="r">{{ detailData.product.store_info }}</div>
                  </div>
                </div>
                <div class="detail-title ft12 fw500">详情介绍</div>
                <div class="detail-desc" v-if="detailData && detailData.product">
                  <div
                    class="detail-img"
                    v-for="(item, index) in detailData.product.slider_image"
                    :key="index"
                  >
                    <img
                      mode="widthFix"
                      :src="item + '!m640'"
                      v-if="item"
                    >
                  </div>
                </div>
                <div class="detail-desc padding">
                  <div v-html="detailData.content" />
                </div>
              </van-list>
            </div>
          </div>
        </template>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Popup, Toast } from "vant";
import { ref, computed } from "vue";
import { auctionDetail } from "@/api/auction";
import { parseTime, formatPriceNumber } from "@/utils/util";
import { useStore } from 'vuex';

export default {
  components: {
    [Popup.name]: Popup,
  },
  props: {
    merchant: Object,
  },
  setup(props) {
    const show = ref(false);
    const isLoading = ref(false);
    const detailData = ref({});

    const loadInit = (id) => {
      detailData.value = {};
      show.value = true;
      auctionDetail(id)
        .then((res) => {
          detailData.value = res.data;
        })
        .catch((message) => {
          Toast(message);
          show.value = false;
        });
    };

    const store = useStore();
    const merchantInfo = computed(() => {
      if (props.merchant) {
        return props.merchant;
      }
      return store.getters.merchantInfo ? store.getters.merchantInfo : {};
    });

    return {
      show,
      detailData,
      isLoading,
      merchantInfo,

      parseTime,
      formatPriceNumber,
      loadInit,
    };
  },
};
</script>

<style lang="scss" scoped>
.auction-detail {
  flex-direction: column;
  height: 100%;
}
.all-t {
  padding: 32px;
}

.all-t div {
  font-size: 32px;
}

.all-t image {
  width: 48px;
  height: 48px;
}
.detail-name {
  padding: 0 32px;
  font-size: 32px;
}
.detail-title {
  margin: 0 32px;
  padding: 32px 0;
}
.detail-desc {
  margin: 0 32px;
  &.padding {
    padding-top: 32px;
    padding-bottom: 82px;
  }
}

.detail-desc .detail-img,
.detail-desc .detail-img img {
  width: 100%;
}

.detail-desc .detail-img {
  margin-top: 32px;
}

.detail-desc .detail-img:first-child {
  margin-top: 0;
}
.auction-list {
  flex: 1;
  overflow: hidden;
}

.auction-list .list-show {
  width: 100%;
  height: 100%;
  overflow: auto;
}
.auction-list .list {
  width: 100%;
  height: 100%;
}
.params {
  margin-top: 32px;
  font-size: 24px;
  padding: 0 32px;
  .flex {
    padding: 15px 0;
    line-height: 40px;
    border-bottom: 2px solid #eaeced;
  }
  .l {
    width: 140px;
    margin-right: 14px;
    color: #000;
  }

  .r {
    color: #999;
    flex: 1;
    max-height: 75000px;
  }

  .link {
    color: #347ff4;
  }
}
</style>
